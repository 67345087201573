/*
 *   File : update-nft.js
 *   Author URI : https://evoqins.com
 *   Description : Update Email, Mobile, Nominee against Folio
 *   Integrations : null
 *   Version : v1.1
 */

// import package
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// import components
import { BreadCrumb } from "../../Component/BreadCrumb";
import Icon from "../../Component/Icon/icon";
import { GradientButton } from "../../Component/CTA";
import { DataTable } from "../../Component/Form-elements";
import { InvestmentHeader } from "../../Component/Header";
import Footer from "../../Component/Footer/footer";
import { UpdateFolioMail, ManageNomineeModal, UpdateFolioMobile } from "../../Component/Modal";
import { CustomLoader, EmptyScreen } from "../../Component/Other";

// import style and color
import Color from '../../Styles/color.module.scss';

// services
import APIService from "../../Service/api-service";

// Bread crumbs data
const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Profile', url: '/profile' },
    { page: 'Update NFT', url: '/profile' },
    { page: 'Update nominee details', url: null },
];

const TABLE_STYLE = {
    table: {
        style: {
            paddingLeft: "0px",
            paddingRight: "0px"
        }
    },
    headRow: {
        style: {
            gap: "56px",
        }
    },
    headCells: {
        style: {
            color: Color.black,
            fontSize: "1rem",
            fontFamily: "Poppins-Medium",
            fontWeight: "500",
            lineHeight: "1.125rem",
            '&:not(:first-child)': {
                justifyContent: 'start',
            },
        }
    },
    rows: {
        style: {
            cursor: "default",
            gap: "56px",
            '&:last-child': {
                marginBottom: '0',
            },
        },
    },
    cells: {
        style: {
            color: Color.black,
            fontFamily: "Poppins-Medium",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            justifyContent: "start",
            padding: '15px 0px !important'
        }
    }
};

export default function UpdateNFT(props) {

    const location = useLocation();

    const [nftType, setNftType] = useState(1);
    const [headerText, setHeaderText] = useState("nominee details");
    const [breadCrumb, setBreadCrumb] = useState([PAGE_NAVIGATION]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [pageLoader, setPageLoader] = useState(false);
    const [folios, setFolios] = useState([]);
    const [nomineeList, setNomineeList] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [openNomineeModal, setOpenNomineeModal] = useState(false);
    const [openUpdateMailModal, setOpenUpdateMailModal] = useState(false);
    const [openUpdateMobileModal, setOpenUpdateMobileModal] = useState(false);

    const NOMINEE = [
        {
            name: "",
            selector: '',
            sortable: false,
            width: '0',
            cell: row =>
                <div className="w-100 text-center">
                    <Icon icon={selectedRow && selectedRow.folio_number === row['folio_number'] ? "radio-selected" : "radio-unselected"}
                        size={22}
                        className="cursor-pointer"
                        onClick={(event) => _handleRowCheckBox(row, event)} />
                </div>
        },
        {
            name: 'Folio',
            selector: row => row['folio_number'],
            sortable: false,
            width: '10%',
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['folio_number']}</p>
        },
        {
            name: 'Fund',
            selector: row => row['fund_name'],
            sortable: false,
            width: "30%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['fund_name']}</p>
        },
        {
            name: 'Primary holder',
            selector: row => row['holder_name'],
            sortable: false,
            // width: "30%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['holder_name']}</p>
        },
        {
            name: 'Nominee details',
            selector: row => row['nominee_added'],
            sortable: false,
            width: "20%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['nominee_added'] === true ? 'Added' : 'Not added'}</p>
        },
    ];

    const EMAIL = [
        {
            name: "",
            selector: '',
            sortable: false,
            width: '0',
            cell: row =>
                <div className="w-100 text-center">
                    <Icon icon={selectedRow && selectedRow.folio_number === row['folio_number'] ? "radio-selected" : "radio-unselected"}
                        size={22}
                        className="cursor-pointer"
                        onClick={(event) => _handleRowCheckBox(row, event)} />
                </div>
        },
        {
            name: 'Folio',
            selector: row => row['folio_number'],
            sortable: false,
            width: '10%',
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['folio_number']}</p>
        },
        {
            name: 'Fund',
            selector: row => row['fund_name'],
            sortable: false,
            width: "30%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['fund_name']}</p>
        },
        {
            name: 'Holder',
            selector: row => row['holder_name'],
            sortable: false,
            // width: "30%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['holder_name']}</p>
        },
        {
            name: 'Email',
            selector: row => row['email'],
            sortable: false,
            // width: "30%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['email']}</p>
        },
    ];

    const MOBILE = [
        {
            name: "",
            selector: '',
            sortable: false,
            width: '0',
            cell: row =>
                <div className="w-100 text-center">
                    <Icon icon={selectedRow && selectedRow.folio_number === row['folio_number'] ? "radio-selected" : "radio-unselected"}
                        size={22}
                        className="cursor-pointer"
                        onClick={(event) => _handleRowCheckBox(row, event)} />
                </div>
        },
        {
            name: 'Folio',
            selector: row => row['folio_number'],
            sortable: false,
            width: '10%',
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['folio_number']}</p>
        },
        {
            name: 'Fund',
            selector: row => row['fund_name'],
            sortable: false,
            width: "30%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['fund_name']}</p>
        },
        {
            name: 'Holder',
            selector: row => row['holder_name'],
            sortable: false,
            // width: "30%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['holder_name']}</p>
        },
        {
            name: 'Mobile',
            selector: row => row['mobile'],
            sortable: false,
            // width: "30%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['mobile']}</p>
        },
    ];

    useEffect(() => {
        _getFolios();
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            setNftType(location.state.type);
            let navigation = PAGE_NAVIGATION;
            if (location.state.type === 1) {
                navigation[3].page = 'Update nominee details';
            }
            if (location.state.type === 2) {
                setHeaderText('email');
                navigation[3].page = 'Update email';
            }
            if (location.state.type === 3) {
                setHeaderText('mobile number');
                navigation[3].page = 'Update mobile number';
            }
            setBreadCrumb(navigation);
        }
    }, [location]);

    useEffect(() => {
        if (openNomineeModal === true) {
            const modal = new Modal(document.getElementById("manage-nominee"), {});
            modal.show();
        }
    }, [openNomineeModal]);

    useEffect(() => {
        if (openUpdateMailModal === true) {
            const modal = new Modal(document.getElementById("nft-email"), {});
            modal.show();
        }
    },[openUpdateMailModal]);

    useEffect(() => {
        if (openUpdateMobileModal === true) {
            const modal = new Modal(document.getElementById("nft-mobile"), {});
            modal.show();
        }
    },[openUpdateMobileModal]);

    function _handleRowCheckBox(row) {
        setSelectedRow(row);
    }

    function _handleCloseNomineeModal() {
        setOpenNomineeModal(false);
    }

    function _handleSubmit() {
        if (selectedRow === null) {
            toast.dismiss();
            toast.error('Please select any folio to proceed', {
                type: 'error'
            });
            return;
        }
        if (location.state.type === 1) {
            _getNomineeDetails();
        } else if (location.state.type === 2) {
            setOpenUpdateMailModal(true);
        }else{
            setOpenUpdateMobileModal(true);
        }
    }

    function _handleUpdateMail () {
        setOpenUpdateMailModal(false);
        _getFolios(false);
    }

    function _handleUpdateMobile () {
        setOpenUpdateMobileModal(false);
        _getFolios(false);
    }

    // API - get nominee details
    function _getNomineeDetails() {
        setButtonLoader(true);
        let url = 'mf-services/nft/list-nominees';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setNomineeList(response.data);
                setOpenNomineeModal(true);
            }
            setButtonLoader(false);
        })
    }

    // API - listing available folios
    function _getFolios() {
        setPageLoader(true);
        let url = 'mf-services/nft/get-folios';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setFolios(response.data);
            }
            setPageLoader(false);
        })
    }

    return (
        <Fragment>
            {
                pageLoader === true ?
                    <div className="h-100vh">
                        <CustomLoader />
                    </div>
                    :
                    <div className="e-profile e-page-container margin-72px-bottom pb-lg-0 pb-md-0 pb-sm-0 pb-4">

                        {/* Header */}
                        <InvestmentHeader />

                        <BreadCrumb data={breadCrumb} 
                            redirectToTab={true}/>

                        <h1 className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mt-4 mb-3">
                            Update {headerText}
                        </h1>

                        {
                            folios.length !== 0 ?
                                <>
                                    <h6 className="e-poppins-semi-bold e-font-16 color-black">Select folio</h6>

                                    <DataTable columns={nftType === 1 ? NOMINEE : nftType === 2 ? EMAIL : MOBILE}
                                        data={folios}
                                        pagination={false}
                                        customStyles={TABLE_STYLE} />

                                    <GradientButton label={location.state.type === 1 ? "Next" : 'Submit'}
                                        className="padding-12px-tb padding-16px-lr mt-4"
                                        handleClick={_handleSubmit} />
                                </>
                                :
                                <div className="">
                                    <EmptyScreen />
                                </div>
                        }

                    </div>
            }
            {
                openNomineeModal === true ?
                    <ManageNomineeModal data={Object.keys(nomineeList).length !== 0 ? nomineeList.nominees : []}
                        update={() => {
                            setOpenNomineeModal(false);
                        }}
                        nft={true}
                        folio={selectedRow.folio_number}
                        close={_handleCloseNomineeModal} />
                    :
                    null
            }
            {
                openUpdateMailModal === true &&
                <UpdateFolioMail email={selectedRow.email}
                    nft={true}
                    folio={selectedRow.folio_number}
                    onCloseModal={()=> setOpenUpdateMailModal(false)}
                    mailAdded={() => {
                        _handleUpdateMail();
                    }} />
            }

            {
                openUpdateMobileModal === true &&
                <UpdateFolioMobile mobile={selectedRow.mobile}
                    folio={selectedRow.folio_number}
                    onCloseModal={()=> setOpenUpdateMobileModal(false)}
                    mobileAdded={() => {
                        _handleUpdateMobile();
                    }}/>
            }

        </Fragment>
    )
}
