/*
 *   File : file-preview.js
 *   Author : https://evoqins.com
 *   Description : UI element to show preview of the uploaded file. 
 *   Integrations : null
 *   Version : 1.0.0
*/
import { useState } from "react";

import Icon from "../Icon/icon"

const FilePreview = (props) => {

    const [hoverOverBin, setHoverOverBin] = useState(false);

    function _hoverBin () {
        setHoverOverBin(!hoverOverBin);
    }
    return (
        <div className="border-radius-8px border-all-dashed border-dark-spring-green bg-greenish-white px-3 padding-12px-tb d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-8px overflow-hidden">
                <img src={props.fileUrl?.length !== 0 ? props.fileUrl : require("../../Assets/Images/profile/file-preview.png")}
                    alt="File preview"
                    width={24}
                    height={24}
                    className="object-fit-contain" />
                <div>
                    <div >
                        <p className="color-jett-black e-poppins-regular e-font-10 mb-0 line-height-22px overflow-wrap-anywhere white-space-nowrap text-overflow-ellipsis overflow-hidden ">{props.fileName}</p>
                        {props.fileSize !== null && <p className="mt-1 color-black e-poppins-regular e-font-8 mb-0">File size: {props.fileSize}</p>}
                    </div>
                </div>
            </div>
            <div className="d-flex gap-8px align-items-center">
                {
                    props.preview === true && (
                        <Icon icon="preview"
                            size={24}
                            className="cursor-pointer"
                            onClick={() => props.previewFile()} />
                    )
                }
                {
                    props.showBinIcon === true && (
                        <Icon icon={hoverOverBin === true ? "delete-red" : "delete"}
                            size={24}
                            className="cursor-pointer"
                            onMouseEnter={_hoverBin}
                            onMouseLeave={_hoverBin}
                            onClick={() => props.removeFile()} />
                    )
                }

            </div>
        </div>
    )
};

FilePreview.defaultProps = {
    showBinIcon: true,
    preview: false,
    fileSize: null,
    fileUrl: "",
    removeFile: () => { },
    previewFile: () => { }
}

export default FilePreview