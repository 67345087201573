/*
 *   File : ipv-success.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for showing payment status. 
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";

import Icon from "../Icon/icon";

import style from "../../Styles/Component/cancel-sip.module.scss";

const IPVSuccess = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("ipv-status");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    const _handleNavigate = (type) => {
        switch (type) {
            case 1: navigator('/portfolio');
                break;
            case 2: navigator('/orders', { state: { order_type: props.type, filter_type: props.transactionType !== null ? props.transactionType : null } });
                break;
            case 4 : navigator('/sip');
            default: return;
        }
    }

    return (
        <div className={`modal fade ${style.e_sip_modal}`}
            id="ipv-status"
            tabIndex="-1"
            aria-labelledby="ipv-status"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  padding-32px-lr pb-3 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="text-center">
                        <img src={require('../../Assets/Images/orders/order-success.svg').default }
                            alt="Pause SIP"
                            width={72}
                            height={72} />
                    </div>
                    <div className="row pb-4">
                        <div className="col-12 text-center">
                            <h5 className="color-eerie-black e-poppins-medium e-font-18 mt-5">
                                IPV Completed
                            </h5>
                            <p className="color-outer-space e-poppins-regular e-font-14 mb-0  text-center">
                                You successfully completed the IPV verification
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IPVSuccess;