/*
 *   File : manage-email.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to add or update email
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { GradientButton, SecondaryButton } from "../CTA";
import Icon from "../Icon/icon";
import { OTPInput, CustomTextInput, CustomSelectBox } from "../Form-elements";
import { useKeyHandler } from "../../Helper";

import style from "../../Styles/Component/manage.module.scss";
import Color from "../../Styles/color.module.scss";
import APIService from "../../Service/api-service";
import { _getMobileOrEmailRelation } from "../../Helper/api";

const UpdateFolioMail = (props) => {
    const navigator = useNavigate();
    const location = useLocation();

    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [email, setEmail] = useState('');
    const [otpSent, setOtpSent] = useState(0);
    const [seconds, setSeconds] = useState(30);
    const [otp, setOtp] = useState("");
    const [loader, setLoader] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [otpError, setOtpError] = useState("");
    const [selectedRelation, setSelectedRelation] = useState("");
    const [relationError, setRelationError] = useState("");
    const [relationOptions, setRelationOptions] = useState([]);
    const [otpNewMail, setOtpNewMail] = useState('');
    const [optNewMailError, setOtpNewMailError] = useState('');
    const [currentMailVerified, setCurrentMailVerified] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("nft-email");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.onCloseModal();
        });
    }, []);


    useEffect(() => {
        _getRelation();
    }, []);

    useEffect(() => {
        if (otpSent === 1) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                    // Handle expiration logic here (e.g., show a resend button)
                }
            }, 1000);
            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, otpSent]);

    // useKeyHandler('manage-email', 'a', (event) => {
    //     if (event.ctrlKey || event.metaKey) {
    //         event.preventDefault();
    //     }
    // });

    const _handleGoBack = (type) => {
        setEmailError("")
        setOtpError("");
        setOtpNewMailError("");
        setOtpSent(0);
    }

    const _handleChange = (type, value) => {
        if (type === 1) {
            setOtp(value)
        } else {
            setOtpNewMail(value);
        }
    };

    async function _validateOTP() {
        let valid = true;
        if (otp.length != 6) {
            setOtpError('Old OTP is required');
            valid = false;
        }
        if (otpNewMail.length !== 6) {
            setOtpNewMailError('New OTP is required');
        }
        if (valid === true) {
            _verifyEmail(otp)

        }
    }


    const _handleInput = (value) => {
        setEmailError("");
        setEmail(value);
    }

    const _handleSelectBox = (value) => {
        setSelectedRelation(value);
        setRelationError("");
    }

    // close modal without any update in data 
    const _closeModal = () => {
        console.log('closi')
        props.onCloseModal();
        document.getElementById("close-modal").dispatchEvent(new Event("click"));
    }

    const _handleSendOTP = () => {
        let valid = true;
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            valid = false;
            setEmailError('Enter valid email');
        }
        if (valid === true) {
            _changeEmail();
        }
    }

    const _handleResendOtp = () => {
        setOtp("");
        _changeEmail();
    }

    function _navigateToProfile () {
        navigator('/profile', { state: { tab: location.state.tabIndex } })
    }

    const _getRelation = async () => {
        let data = await _getMobileOrEmailRelation();
        setRelationOptions(data);
        let relation_index = data.findIndex((item) => item.value === USER_DATA.email_relation_id);
        if (relation_index !== -1) {
            setSelectedRelation(data[relation_index]);
        } else {
            setSelectedRelation(data[0]);
        }
    }


    // API - Send OTP to existing email and new email
    const _changeEmail = () => {
        setLoader(true);
        let url = 'mf-services/nft/change-email';
        let data = {
            "folio_numbers": [props.folio],
            "email": email.toLowerCase(),
            "relation": selectedRelation.value
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSeconds(29);
                setOtpSent(1);
                toast.dismiss();
                toast.success('OTP has been sent successfully', {
                    type: "success"
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }


    // API - Verify new mail and update profile
    const _verifyEmail = async (otp) => {
        setLoader(true);  // Assuming you want to show a loader before the API call
        let url = 'mf-services/nft/verify-email-otp';
        let data = {
            "otp": otp
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                if (currentMailVerified === false) {
                    setCurrentMailVerified(true);
                    setOtpSent(1);
                    setOtp("");
                } else {
                    setOtpSent(2);
                }
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });

    }





    return (
        <div className={`modal fade ${style.e_manage_modal}`}
            id="nft-email"
            tabIndex="-1"
            aria-labelledby="nft-email"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content p-4 ${style.e_content} `}>
                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                    {
                        otpSent === 1 ?
                            // OTP screen If email already existed
                            <Fragment>
                                {/* title */}
                                <>
                                    <Icon icon="close"
                                        size={24}
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                        onClick={_closeModal} />

                                    <h3 className="color-black e-poppins-medium e-font-16 line-height-16px d-flex align-items-center mb-3 mt-2">
                                        <Icon icon="back-arrow"
                                            size={24}
                                            color={Color.black}
                                            className={`me-2 cursor-pointer`}
                                            onClick={() => _handleGoBack(1)} />Verify OTP
                                    </h3>
                                </>


                                {/* Email and OTP input for existing email */}
                                <div className="text-center mt-4">
                                    <img src={require("../../Assets/Images/portfolio/redeem-confirm.png")}
                                        alt="Confirm payment"
                                        width={72}
                                        height={72} />
                                    {
                                        currentMailVerified === false ?
                                        <>
                                            <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Enter OTP you have received in {props.email}</p>

                                            <p className="color-jett-black e-poppins-regular e-font-16 mb-3">Submit OTP to verify your current email.</p>

                                            <p className={`color-black e-poppins-medium e-font-14 mb-1 text-center`}>
                                                Enter OTP received at <span className="color-outer-space e-poppins-regular">{props.email}</span>
                                            </p>
                                        </>
                                        :
                                        <>
                                            <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Enter OTP you have received in {email}</p>

                                            <p className="color-jett-black e-poppins-regular e-font-16 mb-3">Submit OTP to verify your new email.</p>

                                            <p className={`color-black e-poppins-medium e-font-14 mb-1 text-center`}>
                                                Enter OTP received at <span className="color-outer-space e-poppins-regular">{email}</span>
                                            </p>
                                        </>
                                    }

                                    <OTPInput id="Pin"
                                        autoFocus={true}
                                        type={2}
                                        className={`e_width_386 `}
                                        pinError={otpError}
                                        pinString={otp}
                                        handleChange={(value) => _handleChange(1, value)} />
                                </div>



                                {/* cancel and update OTP */}
                                <div className="padding-32px-top d-flex justify-content-center gap-8px">
                                    <GradientButton label="Confirm OTP"
                                        loading={loader}
                                        className="e-font-16 padding-72px-lr padding-12px-tb"
                                        handleClick={_validateOTP} />

                                </div>
                                {
                                    seconds === 0 ?
                                        <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 mt-3 text-center'
                                            onClick={_handleResendOtp}>Resend OTP</p>
                                        :
                                        <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 mt-3 text-center'>Resend OTP in <span className="color-black">{seconds}s</span></p>
                                }
                            </Fragment>
                            : otpSent === 0 ?
                                <Fragment>
                                    <Icon icon="close"
                                        size={24}
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                        onClick={_closeModal} />

                                    <h3 className="color-black e-poppins-medium e-font-16 line-height-16px mb-0 mt-2">
                                        Edit Email
                                    </h3>

                                    <p className="color-outer-space e-poppins-regular e-font-14 mb-4 mt-4 pe-5">
                                        To update the current email associated with your folio, 
                                        you need to verify both your current and new email addresses. 
                                        On next screen please enter the OTP sent to your current email address first, 
                                        then proceed to verify the new email address.
                                    </p>

                                    <div className="row">
                                        <div className="col-md-8 col-12 pb-4">
                                            <CustomTextInput label="Enter new mail id"
                                                postfix="*"
                                                error={emailError}
                                                value={email}
                                                handleChange={_handleInput} />
                                        </div>
                                        <div className="col-md-8 ">
                                            <CustomSelectBox value={selectedRelation}
                                                label="Relation"
                                                error={relationError}
                                                postfix="*"
                                                className="margin-32px-bottom "
                                                options={relationOptions}
                                                onSelectChange={_handleSelectBox} />
                                        </div>
                                    </div>

                                    {/* send OTP or Close teh modal */}
                                    <div className="padding-32px-top d-flex justify-content-center gap-8px">
                                        <SecondaryButton label="Cancel"
                                            className="e-font-16 e-poppins-regular px-4 padding-12px-tb"
                                            handleClick={_closeModal} />

                                        <GradientButton label="Send OTP"
                                            loading={loader}
                                            className="e-font-16 padding-72px-lr padding-12px-tb"
                                            handleClick={_handleSendOTP} />

                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                                    <div className="text-center">
                                        <img src={require('../../Assets/Images/orders/order-success.svg').default}
                                            alt="Pause SIP"
                                            width={72}
                                            height={72} />
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h5 className="color-eerie-black e-poppins-medium e-font-18 mt-5">
                                                Data added successfully.
                                            </h5>
                                            <p className="color-outer-space e-poppins-regular e-font-14 mb-0  text-center">
                                                Your request is successfully submitted.
                                            </p>
                                        </div>
                                        <div className="mt-4 pt-3 d-sm-flex d-block justify-content-center gap-8px mb-3">

                                            <GradientButton label="Back to home"
                                                cancel="modal"
                                                className="e-font-16 padding-70px-lr padding-12px-tb w-mobile-100 mt-sm-0 mt-3"
                                                handleClick={_navigateToProfile} />
                                        </div>
                                    </div>
                                </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default UpdateFolioMail